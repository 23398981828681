import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { RequestTypes, ResponseType, useWebsocketContext } from '../../context/WebsocketContext';
import greenTick from '../../assets/icons/green-tick.svg';
import styles from './index.module.css';
import { useTranslation } from 'react-i18next';

type Props = {
  match: {
    params: {
      token: string;
    };
  };
};

export default function Registration({
  match: {
    params: { token },
  },
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [code, setCode] = useState<string | null>(null);
  const [isTCAccepted, setIsTCAccepted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [boothToken, setBoothToken] = useState<string | null>(null);
  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

  const { i18n } = useTranslation();

  const { send, onMessage } = useWebsocketContext();

  const processIncomingMessage = useCallback((data: ResponseType) => {
    console.log('got new message:', data);
    switch (data.type) {
      case RequestTypes.BoothValidation:
        setIsTokenValid(data.isValid);
        break;
      case RequestTypes.Registration:
        if (data.reason) {
          console.error('Registration failed with the reason', data.reason);
          return;
        }
        setCode(data.code);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    onMessage(processIncomingMessage);
    // as soon as we start, send the booth token to the server to verify if it's still valid
    setBoothToken(token);
    if (!token) {
      setIsTokenValid(false);
      return;
    }
    send({ type: RequestTypes.BoothValidation, token });
  }, [token, send, onMessage, processIncomingMessage]);

  useEffect(() => {
    setName(localStorage.getItem('name') ?? '');
    setEmail(localStorage.getItem('email') ?? '');
  }, []);

  const isNameValid = useMemo(() => name, [name]);

  const isEmailValid = useMemo(() => {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!email || !regex.test(email)) return false;
    return true;
  }, [email]);

  const isTCValid = useMemo(() => isTCAccepted, [isTCAccepted]);

  const isFormValid = useMemo(() => isNameValid && isEmailValid && isTCValid, [isNameValid, isEmailValid, isTCValid]);

  const errorMessages = useMemo(() => {
    return !isSubmitted
      ? []
      : [
          !isNameValid ? t('registration.enterName') : '',
          !isEmailValid ? t('registration.enterEmail') : '',
          !isTCValid ? t('registration.acceptTerms') : '',
        ];
  }, [isSubmitted, isNameValid, t, isEmailValid, isTCValid]);

  const onSubmit = useCallback(() => {
    setIsSubmitted(true);
    if (!isTCAccepted || !boothToken) return;
    localStorage.setItem('name', name);
    localStorage.setItem('email', email);
    send({ type: RequestTypes.Registration, token: boothToken, email, name, language: i18n.language });
  }, [email, name, isTCAccepted, boothToken, send]);

  const tokenInvalidView = useMemo(
    () => (
      <>
        <div className={styles.errorView}>
          <div className={styles.title}>{t('registration.registrationError')}</div>
          <div
            style={{
              marginTop: '2rem',
              padding: '0 3.6rem',
              textAlign: 'center',
            }}
          >
            {t('registration.codeExpired')}
          </div>
        </div>
      </>
    ),
    [t]
  );

  const registrationView = useMemo(
    () => (
      <>
        <div className={styles.title}>{t('registration.registration')}</div>
        <input
          type='text'
          maxLength={30}
          placeholder={t('registration.name').toString()}
          value={name}
          className={!isSubmitted || isNameValid ? '' : 'invalid'}
          onChange={e => setName(e.target.value)}
        />
        <input
          type='email'
          maxLength={320}
          placeholder={t('registration.emailaddress').toString()}
          value={email}
          className={!isSubmitted || isEmailValid ? '' : 'invalid'}
          onChange={e => setEmail(e.target.value)}
        />
        <div
          className={`checkbox ${!isSubmitted || isTCValid ? '' : 'invalid'}`}
          onClick={() => setIsTCAccepted(i => !i)}
        >
          <div className='green-tick-container'>
            {isTCAccepted && <img src={greenTick} alt='green tick' className='green-tick' />}
          </div>
          {t('registration.agree')}
          <a
            href='https://www.snipes.com/content/agb.html'
            target='_blank'
            rel='noreferrer'
            style={{ color: '#FF6700', marginLeft: '5px' }}
          >
            {t('registration.terms')}
          </a>
        </div>
        {isTokenValid && (
          <button className={`${styles.submitButton} ${isFormValid ? 'active' : ''}`} onClick={onSubmit}>
            {t('registration.submit')}
          </button>
        )}
        {errorMessages.map((errorMessage, i) => {
          return errorMessage ? (
            <div className='error' key={i}>
              {errorMessage}
            </div>
          ) : (
            ''
          );
        })}
      </>
    ),
    [
      t,
      name,
      isSubmitted,
      isNameValid,
      email,
      isEmailValid,
      isTCValid,
      isTCAccepted,
      isTokenValid,
      isFormValid,
      onSubmit,
      errorMessages,
    ]
  );

  const codeView = useMemo(
    () =>
      code ? (
        <>
          <div className={styles.title}>{t('registration.registrationSuccess')}</div>
          <div className={styles.individual}>{t('registration.codeUnlock')}</div>
          <div className={styles.codeViewer}>
            <div className={styles.codeDigit}>
              <span>{code[0]}</span>
            </div>
            <div className={styles.codeDigit}>
              <span>{code[1]}</span>
            </div>
            <div className={styles.codeDigit}>
              <span>{code[2]}</span>
            </div>
            <div className={styles.codeDigit}>
              <span>{code[3]}</span>
            </div>
          </div>
          <div className={styles.enterCode}>{t('registration.enterCode')}</div>
          <div className={styles.attention}>{t('registration.attention')}</div>
        </>
      ) : (
        <></>
      ),
    [code, t]
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          {isTokenValid === false && tokenInvalidView}
          {(isTokenValid === null || isTokenValid) && !code && registrationView}
          {isTokenValid && codeView}
        </div>
      </div>
    </>
  );
}
