import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          registration: {
            name: 'Name',
            emailaddress: 'Email Address',
            enterName: 'Please enter your name',
            enterEmail: 'Please enter your email',
            acceptTerms: 'Please accept Terms & Conditions',
            registrationError: 'REGISTRATION ERROR',
            codeExpired: 'The code has expired. Please scan the QR code in the soundbooth again.',
            registration: 'REGISTRATION',
            agree: 'I agree to',
            terms: 'Terms & Conditions',
            submit: 'SUBMIT',
            registrationSuccess: 'REGISTRATION SUCCESSFUL',
            codeUnlock: 'This is your individual code to unlock the soundbooth',
            enterCode: 'Enter this code on screen',
            attention: 'Attention! This code is only valid for 24 hours and will automatically expire afterwards.',
          },
          download: {
            safari: 'Please open this page in safari in order to be able to download your track.',
            copyUrl: 'Copy Url',
            uploading: 'Uploading Video please check out later.',
            track: 'YOUR TRACK IS',
            ready: 'READY TO BE SHARED',
            session: 'Download Session',
            audio: 'Download Audio',
            loading: 'Loading...',
          },
        },
      },
      fr: {
        translation: {
          registration: {
            name: 'Nom',
            emailaddress: 'Adresse e-mail',
            enterName: 'Entre ton nom',
            enterEmail: 'Entre ton e-mail',
            acceptTerms: "Accepter les Termes et Conditions d'utilisation",
            registrationError: "ERREUR D'INSCRIPTION",
            codeExpired: 'Le code a expiré. Scanne à nouveau le QR code dans le soundbooth.',
            registration: 'INSCRIPTION',
            agree: "J'accepte les",
            terms: "Termes et Conditions d'utilisation",
            submit: 'VALIDER',
            registrationSuccess: 'INSCRIPTION ENREGISTRÉE',
            codeUnlock: 'Ceci est ton code individuel pour accéder au soundbooth',
            enterCode: "Entre ce code sur l'écran",
            attention:
              "Attention! Ce code n'est valide que pendant 24 heures et expirera automatiquement après ce délai.",
          },
          download: {
            safari: 'Ouvre cette page sur ton navigateur afin de pouvoir télécharger ton morceau',
            copyUrl: "Copie l'URL",
            uploading: 'Téléchargement de la vidéo en cours.',
            track: 'TON MORCEAU EST',
            ready: 'PRÊT À ÊTRE PARTAGÉ',
            session: 'Télécharger la session',
            audio: "Télécharger l'audio",
            loading: 'Chargement...',
          },
        },
      },
      de: {
        translation: {
          registration: {
            name: 'Name',
            emailaddress: 'E-Mail-Adresse',
            enterName: 'Bitte gib deinen Namen ein',
            enterEmail: 'Bitte gib deine E-Mail-Adresse ein',
            acceptTerms: 'Bitte akzeptiere die Allgemeinen Geschäftsbedingungen',
            registrationError: 'REGISTRIERUNGSFEHLER',
            codeExpired: 'Der Code ist abgelaufen. Bitte scanne den QR-Code in der Soundbooth erneut.',
            registration: 'REGISTRIERUNG',
            agree: 'Ich stimme zu',
            terms: 'Allgemeine Geschäftsbedingungen',
            submit: 'ABSENDEN',
            registrationSuccess: 'REGISTRIERUNG ERFOLGREICH',
            codeUnlock: 'Dies ist dein individueller Code, um deine Soundbooth Session zu starten',
            enterCode: 'Gib diesen Code auf dem Bildschirm ein',
            attention: 'Achtung! Dieser Code ist nur 24 Stunden gültig und wird danach automatisch ablaufen.',
          },
          download: {
            safari: 'Bitte öffne diese Seite in Safari, um deinen Track herunterzuladen.',
            copyUrl: 'URL kopieren',
            uploading: 'Video wird hochgeladen, bitte später prüfen.',
            track: 'DEIN TRACK IST',
            ready: 'BEREIT ZUM TEILEN',
            session: 'Session herunterladen',
            audio: 'Audio herunterladen',
            loading: 'Laden...',
          },
        },
      },
    },
  });

export default i18n;
