import React, { useMemo } from 'react';
import snipesLogo from '../assets/images/snipes logo.png';
import bannerBg from '../assets/images/banner bg.png';
import RAISED_FROM_CONCRETE from '../assets/images/RAISED FROM CONCRETE.png';
import ON_AIR from '../assets/images/ON AIR.png';
import styles from './index.module.css';

export enum EnumBannerPlacement {
  Left,
  Right,
  Bottom,
}

export function Banner({ placement }: { placement: EnumBannerPlacement }): JSX.Element {
  const containerStyle = useMemo((): React.CSSProperties => {
    switch (placement) {
      case EnumBannerPlacement.Left:
        return {
          position: 'fixed',
          left: '0rem',
          top: '0rem',
          bottom: '0rem',
          width: '6.3rem',
        };
      case EnumBannerPlacement.Right:
        return {
          position: 'fixed',
          right: '0rem',
          top: '0rem',
          bottom: '0rem',
          width: '6.3rem',
          transform: 'rotate(180deg)',
        };
      case EnumBannerPlacement.Bottom:
        return {
          position: 'fixed',
          left: '0rem',
          bottom: '2.8rem',
          width: '6.3rem',
          transform: 'rotate(-90deg)',
        };
    }
  }, [placement]);

  return (
    <>
      <div style={containerStyle} className={styles.banner}>
        <div
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            width: '6.3rem',
          }}
          className={styles.background}
        >
          <img
            src={bannerBg}
            alt='banner'
            style={{
              position: 'absolute',
              left: '0',
              top: '0',
              width: '6.3rem',
            }}
          />
          <img
            src={bannerBg}
            alt='banner'
            style={{
              position: 'absolute',
              left: '0',
              top: '43.7rem',
              width: '6.3rem',
            }}
          />
          <img
            src={bannerBg}
            alt='banner'
            style={{
              position: 'absolute',
              left: '0',
              top: '87.4rem',
              width: '6.3rem',
            }}
          />
          <img
            src={bannerBg}
            alt='banner'
            style={{
              position: 'absolute',
              left: '0',
              top: '131.2rem',
              width: '6.3rem',
            }}
          />
          <img
            src={bannerBg}
            alt='banner'
            style={{
              position: 'absolute',
              left: '0',
              top: '174.9rem',
              width: '6.3rem',
            }}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            bottom: '0',
            width: '6.3rem',
          }}
          className={styles.foreground}
        >
          <img
            src={ON_AIR}
            alt='ON AIR'
            style={{
              position: 'absolute',
              left: '1.9rem',
              top: '0',
              transform: 'rotate(180deg)',
              width: '2rem',
            }}
          />
          <img
            src={snipesLogo}
            alt='snipes logo'
            style={{
              position: 'absolute',
              left: '-0.9rem',
              top: '18rem',
              transform: 'rotate(90deg)',
              height: '2.8rem',
            }}
          />
          <img
            src={RAISED_FROM_CONCRETE}
            alt='RAISED FROM CONCRETE'
            style={{
              position: 'absolute',
              left: '1.9rem',
              top: '28rem',
              transform: 'rotate(180deg)',
              width: '2rem',
            }}
          />
          <img
            src={snipesLogo}
            alt='snipes logo'
            style={{
              position: 'absolute',
              left: '-0.9rem',
              top: '74rem',
              transform: 'rotate(90deg)',
              height: '2.8rem',
            }}
          />
          <img
            src={ON_AIR}
            alt='ON AIR'
            style={{
              position: 'absolute',
              left: '1.9rem',
              top: '84rem',
              transform: 'rotate(180deg)',
              width: '2rem',
            }}
          />
          <img
            src={snipesLogo}
            alt='snipes logo'
            style={{
              position: 'absolute',
              left: '-0.9rem',
              top: '102rem',
              transform: 'rotate(90deg)',
              height: '2.8rem',
            }}
          />
          <img
            src={RAISED_FROM_CONCRETE}
            alt='RAISED FROM CONCRETE'
            style={{
              position: 'absolute',
              left: '1.9rem',
              top: '112rem',
              transform: 'rotate(180deg)',
              width: '2rem',
            }}
          />
          <img
            src={snipesLogo}
            alt='snipes logo'
            style={{
              position: 'absolute',
              left: '-0.9rem',
              top: '158rem',
              transform: 'rotate(90deg)',
              height: '2.8rem',
            }}
          />
          <img
            src={ON_AIR}
            alt='ON AIR'
            style={{
              position: 'absolute',
              left: '1.9rem',
              top: '168rem',
              transform: 'rotate(180deg)',
              width: '2rem',
            }}
          />
          <img
            src={snipesLogo}
            alt='snipes logo'
            style={{
              position: 'absolute',
              left: '-0.9rem',
              top: '186rem',
              transform: 'rotate(90deg)',
              height: '2.8rem',
            }}
          />
          <img
            src={RAISED_FROM_CONCRETE}
            alt='RAISED FROM CONCRETE'
            style={{
              position: 'absolute',
              left: '1.9rem',
              top: '196rem',
              transform: 'rotate(180deg)',
              width: '2rem',
            }}
          />
        </div>
      </div>
    </>
  );
}
